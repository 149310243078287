<template>
	<div class="main1">
		<div class="list" v-for="(item,index) in list" :key="index">
			<musician-list :type="'2'" @openAlert="openAlert" @outGroup="outGroupUser" :index="index"
				:dataObj="item"></musician-list>
		</div>
	</div>
</template>

<script>
	import MusicianList from "@/components/content/MusicianList"
	import { getUserInfo } from "@/utils/common/user";
	import { groupData, outGroupUser,myInfo } from "@/api/Mine"
	export default {
		props: {},
		components: {
			MusicianList
		},
		created() {
			
			this.MyInfo();
			// this.userInfo = JSON.parse(this.getUserInfo())
			// console.log(JSON.parse(this.getUserInfo()),'getUserInfo')
		},
		data(){
			return {
				userInfo: {},//用户信息
				list: [],
				// list: [{
				// 		avatar: 'https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png',
				// 		name: '乔海',
				// 		title: '在项目伊始时做一个用户研究的作用好过于在项目落地后再对50个用户进行测试',
				// 		works: '40',
				// 		fans: '40',
				// 		follow: true,
				// 		id:'1',
				// 		teamLabel: '@梯基音乐',
				// 		type: '1'
				// 	},
				// 	{
				// 		avatar: 'https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png',
				// 		name: '乔海',
				// 		title: '在项目伊始时做一个用户研究的作用好过于在项目落地后再对50个用户进行测试在项目伊始时做一个用户研究的作用好过于在项目落地后再对50个用户进行测试在项目伊始时做一个用户研究的作用好过于在项目落地后再对50个用户进行测试',
				// 		works: '40',
				// 		fans: '40',
				// 		follow: false,
				// 		id:'2',
				// 		teamLabel: '@梯基音乐',
				// 		type: '1'
				// 	},
				// 	{
				// 		avatar: 'https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png',
				// 		name: '乔海',
				// 		title: '在项目伊始时做一个用户研究的作用好过于在项目落地后再对50个用户进行测试',
				// 		works: '40',
				// 		fans: '40',
				// 		follow: false,
				// 		id:'3',
				// 		teamLabel: '@梯基音乐',
				// 		type: '1'
				// 	},
				// 	{
				// 		avatar: 'https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png',
				// 		name: '乔海',
				// 		title: '在项目伊始时做一个用户研究的作用好过于在项目落地后再对50个用户进行测试',
				// 		works: '40',
				// 		fans: '40',
				// 		follow: false,
				// 		id:'4',
				// 		teamLabel: '@梯基音乐',
				// 		type: '1'
				// 	},
				// 	{
				// 		avatar: 'https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png',
				// 		name: '乔海',
				// 		title: '在项目伊始时做一个用户研究的作用好过于在项目落地后再对50个用户进行测试',
				// 		works: '40',
				// 		fans: '40',
				// 		follow: false,
				// 		id:'5',
				// 		teamLabel: '@梯基音乐',
				// 		type: '1'
				// 	}
				// ],
			}
		},
		methods: {
			MyInfo(){
				myInfo({}).then((res) => {
				      console.log(res.data.name,'我的信息成功');
					  this.infoObj = res.data
					  this.$store.state.bankList = res.data.bankcard
					  this.$store.state.groupType = res.data.group_type
					  this.$store.state.isBoss = res.data.is_boss
					  this.$store.state.groupId = res.data.group_id
					  // this.ruleForm.createdMen = res.data.name
					  localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					  // console.log(this.$store.state.groupType,'userInfouserInfouserInfo222222222222')
					  console.log(this.infoObj,'JSON.stringify(this.infoObj)');
					  this.getTeamInfo();//团队信息
					  // if(this.$store.state.groupType == 0){
					  // 	this.tabsList = this.tabsList1
					  // }else{
					  // 	this.tabsList = this.tabsList2
					  // }
				    })
				    .catch((err) => {
				      console.log(err,'我的信息失败');
				    });
			},
			openAlert(data) {
				console.log(data, 'data')
				this.alertShow = true
				// 两秒之后关注成功提示自动关闭
				setTimeout(() => {
					this.alertShow = false
				}, 2000)
			},
			outGroupUser(item){
				let params = {};
				params.creator_id = item.creator_id;
				outGroupUser(params).then((res) => {
				  console.log(res.data);
				  // if (res.status == 200) {
					this.getTeamInfo()
				  // } else {
				    // this.$message({
				    //   type: "error",
				    //   message: res.msg,
				    // });
				  // }
				});
			},
			getUserInfo,
			// 团队信息
			getTeamInfo(){
				
				// this.userInfo = JSON.parse(this.getUserInfo())
				
				// console.log(this.userInfo,'this.userInfo')
				groupData({
					group_id: this.$store.state.groupId,
					group_type: this.$store.state.groupType
				}).then((res) => {
				      console.log(res,'团队信息成功');
					  // this.randCreatorList = res.data
					  this.list = res.data.list
					  // this.numMusician = res.data.count
					  // console.log(res.data.count,'res.data.list.count')
				    })
				    .catch((err) => {
				      console.log(err,'团队信息失败');
				    });
			},
			// toCreate(){
			// 	this.$router.push({
			// 		path: '/teamManagement/createTeamForm'
			// 	})
			// }
		}
	}
</script>

<style lang="less" scoped>
	.main1 {
		width: 100%;
		.list{
			margin-top: 16px;
		}
	}
</style>