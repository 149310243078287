<template>
	<div>
		<div class="list" @click="selectMusic">
			<div class="left">
				<div class="img" v-if="(type == '0') || (type == '3')" @click.stop="toDetail(dataObj.creatorId)">
					<img :src="dataObj.headimage" alt="" srcset="">
				</div>
				<div class="img" v-if="type == '2'" @click.stop="toDetail(dataObj.creator_id)">
					<img :src="dataObj.headimage" alt="" srcset="">
				</div>
				<div class="middle">
					<div class="name">
						<div>{{ dataObj.name }}</div>
						<!-- {{type == '0'}} -- {{dataObj.group_id != ''}} --{{ dataObj.team_name != ''}} -->
						<div v-if="((type == '0') || (type == '3')) && ((dataObj.team_name != '')&&(dataObj.group_id != ''))" @click.stop="toTeamLabel(dataObj.group_id,dataObj.creator_id)" class="team-icon">
							<img src="@/assets/images/recommend/icon-left.png" alt="">
							<div>{{dataObj.group_name ? dataObj.group_name : dataObj.team_name}}</div>
						</div>
						<!-- <div v-if="type == '0' && dataObj.team_name != ''" @click.stop="toTeamLabel(dataObj.group_id,dataObj.creator_id)" class="team-icon">
							<img src="@/assets/images/recommend/icon-left.png" alt="">
							<div>{{dataObj.group_name ? dataObj.group_nam : dataObj.team_name}}</div>
						</div> -->
					</div>
					<div class="title">{{ dataObj.title }}</div>
				</div>
			</div>
			<div class="right">
				<div v-if="(type == '0') || (type == '3')" class="works">
					<div class="title">{{ $t("recommend.works") }}</div>
					<div class="text">{{ dataObj.music_count }}</div>
				</div>
				<div v-if="(type == '0') || (type == '3')" class="fans">
					<div class="title">{{ $t("recommend.fans") }}</div>
					<div class="text">{{ dataObj.follow_number }}</div>
				</div>
				<div v-if="type == '3'" class="follow">
					<div @click.stop="cancelfollow(dataObj.isFollowed, dataObj.creatorId)" class="followed">{{ $t("recommend.followed") }}</div>
				</div>
				<div v-if="type == '0'" class="follow">
					<div @click.stop="followTA(dataObj.isFollowed, dataObj.creatorId)" v-if="dataObj.isFollowed == 0" class="no-follow">{{
							$t("recommend.noFollow")
					}}</div>
					<div @click.stop="cancelfollow(dataObj.isFollowed, dataObj.creatorId)" v-if="dataObj.isFollowed == 1" class="followed">{{ $t("recommend.followed") }}</div>
				</div>
				<!-- <div v-if="follow == '1'" class="follow">
					<div @click.stop="cancelfollow(dataObj.isFollowed, dataObj.creatorId)" v-if="dataObj.isFollowed == 1" class="followed">{{ $t("recommend.followed") }}</div>
				</div> -->
				<div v-if="dataObj.is_boss == '1'">leader</div>
				<div v-if="dataObj.is_boss == '0'">member</div>
				<div v-if="type == '2'">
					<el-dropdown @command="clickDropdown" v-if="dataObj.is_boss == '0' && $store.state.isBoss == '1'">
					  <div class="collect">
						  <Icon name="icon-7key-musicguanli" theme-color="iconColorAll" width="18" height="18">
						  <use xlink:href="#icon-shezhi"></use>	
						  </Icon>
					  </div>
					  <el-dropdown-menu style="float: right" slot="dropdown">
					    <!-- <el-dropdown-item
					       v-if="dataObj.is_boss == '1'"
					      :command="commandValue('0',dataObj)"
					      >{{$t('Disbandteam')}}</el-dropdown-item
					    > -->
					    <!-- <el-dropdown-item
					       v-if="dataObj.is_boss == '0'"
					      :command="commandValue('1',dataObj)"
					      >{{$t('Handoverteam')}}</el-dropdown-item
					    > -->
					    <el-dropdown-item
					      v-if="dataObj.is_boss == '0'"
					      :command="commandValue('2',dataObj)"
					      >{{$t('Outteam')}}</el-dropdown-item
					    >
					  </el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { setTheme } from "@/assets/theme";
export default {
	name: "MusicianList",
	props: {
		// name: String,
		// title: String,
		// avatar: String,
		// works: String,
		// fans: String,
		index: {
			type: Number,
			default: 0
		},
		dataObj: {
			type: Object,
			default() {
				return {}
			}
		},
		type: {
			type: String,
			default: '0'
		}
		// follow: {
		// 	type: Boolean,
		// 	default: false,
		// }
	},
	created() {
		// console.log(this.dataObj, 'dataObj')
	},
	data() {
		return {
			isFollow: false,// 关注状态 true已关注  false未关注
			openAlertShow: false,// 控制关注提示显示
		}
	},
	methods: {
		// 定制 添加音乐人
		selectMusic(){
			this.$emit('selectMusic')
		},
		// 跳转到团队标签页面
		toTeamLabel(group_id,creator_id){
			this.$router.push({
				path: '/recommend/teamLabel',
				query: {
					group_id: group_id,
					group_type: 2,//1企业团队 2音乐人团队
					creator_id: creator_id
				}
			})
			// this.$router.push({
			// 	path: '/musicianList/musicianPersonalCenter',
			// 	query: {
			// 		creator_id: data
			// 	}
			// })
		},
		// 跳转到详情页
		toDetail(id) {
			console.log(id, 'id')
			this.$router.push({
				path: '/musicianList/musicianPersonalCenter',
				query: {
					creator_id: id
				}
			})
			// this.$emit('toDeatil',id)
			// this.$router.push({
			// 	path: '/MusicianPersonalCenterFinished'
			// })
		},
		// 关注
		followTA(follow, creatorId) {
			console.log('关注', follow, creatorId)
			// this.isFollow = !follow
			// this.openAlertShow = true
			let obj = {
				creatorId: creatorId,
				follow: follow
			}
			this.$emit('openAlert',obj)
		},
		cancelfollow(follow, creatorId){
			console.log('取消关注', follow, creatorId)
			// this.isFollow = !follow
			// this.openAlertShow = true
			let obj = {
				creatorId: creatorId,
				follow: follow
			}
			this.$emit('cancelfollow',obj)
		},
		commandValue(type, item) {
		  return {
		    type,
		    item,
		  };
		},
		clickDropdown(command) {
		  // console.log(command);
		  let { type, item } = command;
		  if (type == "0") {
		    console.log("解散团队");
		  } else if (type == "1") {
		    console.log("移交团队");
		  } else {
		    console.log("请出团队");
			this.$emit('outGroup',item)
		    // outGroupUser
		    
		  }
		},
	}
}
</script>

<style scoped lang="less">
.list {
	background: @recommendMusicianListBGColor;
	color: @recommendMusicianListTextColor;
	// width: 100%;
	height: 118px;
	border-radius: 6px;
	display: flex;
	align-items: center;

	// justify-content: space-between;
	.left {
		display: flex;
		flex: 0.7;

		.img {
			margin-left: 16px;
			margin-right: 16px;
			border-radius: 48px;
		}

		.img>img {
			width: 86px;
			height: 86px;
			border-radius: 48px;
			cursor: pointer;
		}

		.middle {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.name {
				margin-bottom: 12px;
				font-weight: Medium;
				font-size: 16px;
				display: flex;

				.team-icon {
					// width: 88px;
					padding: 0 10px;
					height: 20px;
					// background: #FFF6D6;
					// border-radius: 16px;
					color: #FEC002;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 8px;
					background-image: url('../../assets/images/recommend/icon-bg.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					display: flex;
					font-size: 12px;
					-webkit-transform: scale(0.8);
					cursor: pointer;

					img {
						width: 10px;
						height: 8px;
						margin-right: 14px;
					}
				}
			}

			.title {
				color: @recommendMusicianListTitleColor;
				max-width: 400px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 12px;
			}
		}
	}

	.right {
		display: flex;
		flex: 0.3;
		justify-content: space-between;
		padding-right: 16px;
		align-items: center;

		.works {
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				margin-bottom: 8px;
				color: @recommendMusicianListTitleColor;
				font-size: 12px;
			}

			.text {
				font-size: 20px;
			}
		}

		.fans {
			display: flex;
			flex-direction: column;
			align-items: center;

			.title {
				margin-bottom: 8px;
				color: @recommendMusicianListTitleColor;
				font-size: 12px;
			}

			.text {
				font-size: 20px;
			}
		}

		.follow {
			.no-follow {
				width: 88px;
				height: 36px;
				background: #3370FF;
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				font-weight: Semibold;
				font-size: 14px;
				cursor: pointer;
			}

			.followed {
				width: 88px;
				height: 36px;
				background: @recommendMusicianListButtontBGColor;
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: @recommendMusicianListTextColor;
				font-weight: Semibold;
				font-size: 14px;
				cursor: pointer;
			}
		}
		.collect{
			width: 34px;
			height: 34px;
			border-radius: 20px;
			background: @demoitemtabsBGColor;
			margin-left: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
			
			.collect-p {
				width: 18px;
				height: 18px;
			}
		}
	}
}
</style>
